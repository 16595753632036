<template>

  <div class="row">
    <div class="col-12 card">
      <h4>Документарное соответствие (ОТиТБ) v2.0 (2023)</h4>
      <router-link to="/analytics"><h5><span class="icon-ArrowLeft"></span> Назад к Отчётам по Охране труда</h5></router-link>
      <div class="row justify-content-space-between">
        
        <div class="col-12">
          <strong class="col-5 card-title">Выбрать дату</strong>
          <date-picker class="btn-tab btn-tab-active right-space-16" v-model:value="datefilter" range></date-picker>
          <strong class="col-5 card-title">Год:</strong>
          <button class="btn-tab right-space-16" @click="setYear(['Sun Jan 01 2022 00:00:00', 'Sun Dec 31 2022 00:00:00'])">2022</button>
          <button class="btn-tab" @click="setYear(['Sun Jan 01 2023 00:00:00', 'Sun Dec 31 2023 00:00:00'])">2023</button>
        </div>
      </div>
      <div class="row justify-content-space-between top-24">
          <div class="col-6">
            <div class="row justify-content-space-between">

            </div>
          </div>
      </div>
      <strong class="col-5 card-title" v-if="this.datefilter[0]">Период c {{ format_date(this.datefilter[0]) }} по {{ format_date(this.datefilter[1]) }}</strong>
      <div class="row">
        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Организация</label>
            <div class="text-field__icon">
                <div class="select">
                  <select id="standard-select" v-model="this.selectedCompany">
                    <option value="All" selected="selected">Общая аналитика </option>
                    <option v-for="agent in agentsList" 
                      :value="{ id: agent.id }">{{ agent.name }}</option>
                      
                  </select>

                </div>
            </div>
          </div>
        </div>

        <table class="col-12" border="1" v-if="this.selectedCompany == 'All' | this.selectedCompany == ''">
            <thead>
              <tr>
                <th class="width-25">Организация</th>
                <th class="width-25">Кол-во не соответствий</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="company in Companies">
                <td>{{ company.name }}</td>
                <td>{{ countChecklists(company) }}</td>
              </tr>
            </tbody>
          </table>

          <div class="col-12 top-24" v-if="this.selectedCompany != 'All' | this.selectedCompany == ''">
            <strong v-if="typeof lastChecklist != 'undefined'">Последний отчёт:  {{ lastChecklist.period }}</strong>
            <strong v-else>Отчёты за выбранный период отсутствуют</strong>
          </div>

          <table class="col-12" border="1" v-if="this.selectedCompany != 'All' && this.selectedCompany != ''">
            
            <thead>
              <tr>
                <th class="">Соответствие</th>
                <th class="verticalTableHeader" ><p>Значение</p></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="question in Report.questions">
                <td>{{ question.title }}</td>
                <td :class="answerColor(ChecklustAnswer(question.title))" >{{ ChecklustAnswer(question.title) }} </td>
              </tr>
            </tbody>
          </table>
          
      </div>
      <div class="col-12">
        <apexchart type="bar" :options="options" :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import moment from 'moment'

export default {
  data() {
    return {
      title: "Справочники",
      datefilter: [],
      year: '',
      search: null,
      countArray: [],
      selectedCompany: '',
      lastChecklist: [],
      options: {
        chart: {
          id: 'vuechart-example'
        },
        stroke: {
          curve: 'smooth',
          colors: ['#F26650'],
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          colors: '#F26650',
          type: 'solid',
          opacity: 0.5,
        },
       
      },
      series: [{
        name: 'Несоответствия',
      }]
    
    }
  },
  components: { DatePicker },
  computed: {
    agentsList () {
      return this.$store.state.companies
    },
    Companies () {
      return this.$store.state.companies
    },
    GetCompaniesNames() {

      return this.Companies.map(company => company.name) 
    },
    Checklists () {
      return this.$store.state.checklists.filter((checklist) => checklist.finish == true)
      // return this.$store.state.checklists
    },
    Report() {
      return this.$store.state.reports.find((report) => report.id == 2)
    },

    resultQuery() {
      this.countArray = []
      const byDate = (item) => {
        const itemDate = new Date(item.period);
        return (
          itemDate >= new Date(this.datefilter[0]) &&
          itemDate <= new Date(this.datefilter[1])
        );
      };

      const byTitle = (item) =>
        item.title.toLowerCase().includes(this.search.toLowerCase());

      let results = this.Checklists;

      if (this.search) {
        results = results.filter(byTitle);
      }

      const hasDateFilter =
        this.datefilter?.length >= 2 &&
        this.datefilter[0] &&
        this.datefilter[1];

      if (hasDateFilter) {
        results = results.filter(byDate);
      }
      this.updateCategories()
      this.createCountArray(results)

      return results;
    },
  },
  mounted() {
    this.$store.dispatch("fetchReports");
    this.$store.dispatch("fetchCompanies");
    this.$store.dispatch("fetchChecklists", 2);
  },
  methods: {
    ChecklustAnswer(question_title) {
      let company_name = this.$store.state.companies.find((company) => company.id == this.selectedCompany.id).name
      let checklist = this.resultQuery.find((checklist) => checklist.company_name == company_name)
      this.lastChecklist = checklist

      if (checklist) {
        let answer = checklist.answers.find((answer) => answer.question == question_title)
        return answer.answer_result
      }
    },
    answerColor(answer) {
      if (answer == 'Да') {
        return "background_green"
      } else if (answer == 'Нет') {
        return "background_red"
      } else if (answer == 'Не применимо') {
        return "background_gray"
      } else {
        return "background_orange"
      }
    },
    countChecklists(company){
      let amount = 0
      let companyChecklists = this.resultQuery.filter((checklist) => {
          return checklist.company_name.includes(company.name)
        })
      companyChecklists.forEach(function(checklist) { 
        checklist.answers.forEach(function(answer) {
          if (answer.answer_result == 'Нет' | answer.answer_result == '') {
            amount += 1
          }
        })
      })
      return amount
    },
    setYear(year){
      new Date(this.datefilter[0] = year[0])
      new Date(this.datefilter[1] = year[1])
      this.year = year
      return
    },
    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    createCountArray(results) {
      this.Companies.forEach(company => {
        let amount = 0

        let companyChecklists = results.filter((checklist) => {
          return checklist.company_name.includes(company.name)
        })
        companyChecklists.forEach(function(checklist) { 
          checklist.answers.forEach(function(answer) {
            if (answer.answer_result == 'Нет' | answer.answer_result == '') {
              amount += 1
            }
          })
        })
        this.countArray.push(
          amount
        )
      })
    },
    updateCategories(){
      this.series = [
        {
          data: this.countArray
        },
      ];
      this.options = {
        fill: {
          colors: '#F26650',
          type: 'solid',
          opacity: 0.5,
        },
        xaxis: {
          categories: this.GetCompaniesNames
        }
      }
    },
  }
};
</script>

<style scoped>
.verticalTableHeader {
  writing-mode: vertical-lr;
}
.verticalTableHeader p {
    margin:4px 3px;
    display:inline-block;
}
/* .verticalTableHeader p:before {
    content:'';
    width:0;
    padding-top:110%;
    display:inline-block;
    vertical-align:middle;
}
 */
table {
    text-align:center;
    border: 1px;
    
}

/* th {
  width: 100%;
  padding: 2px;
  margin: 3px;
} */
</style>